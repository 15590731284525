import React, { ButtonHTMLAttributes } from 'react';
import { BiLoaderAlt } from 'react-icons/bi';

type ButtonProps = {
    onClick?: () => void;
    type?: 'submit' | 'button' | 'reset';
    size?: any;
    disabled?: boolean;
    text?:any;
    download?:any;
    className?: string;
    children?:any;
    id?:string;
    isLoaderShow?: boolean;
}

const InputBtn = ({ onClick, className, type, id, children, isLoaderShow, ...rest }: ButtonProps) => {
    return (
        <button
            type={type}
            onClick={onClick}
            className={`${className}`}
            id={id}
            {...rest}
        >
            {isLoaderShow ? <BiLoaderAlt className="animate-spin text-white-500" size={18} /> : children}
        </button>
    );
}

InputBtn.defaultProps = {
    type: 'button',
    children: 'Submit',
    isLoaderShow: false
}

export default InputBtn;
