import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { axiosGet, axiosPost } from '../utils/axios'
// import apiRouter from '../utils/apiRouter'

interface notificationProps {
  name: string;
  value: number;
}

interface filterProps {
  filters: [];
  sort: [];
  hideColumns: [],
}

interface initialStateInt {
  isLoading: boolean;
  timezoneArr: any[];
  showSidebar: any;
  windowWidth: any;
  isMobile: boolean;
  twilioAuthToken: string;
  notificationData: notificationProps[];
  wrapperType: any;
  ccmConsentData: [],
  ccmEligibleData: [],
  recordableMinutesData: [],
  recordingStatusList: [],
  userTypes: [],
  providerTypes: [],
  faxTemplateType: [],
  faxDestinationType: [],
  stateList: [],
  providerList: [],
  relationshipList: [],
  reportAdvocateList: [],
  agencyTypes: [],
  overviewType: '',
  filtersObj: filterProps,
  overviewMeasurementValues: [],
  menus: [],
  patientEvents: [],
  organizationList: [],
  campaignList: [],
  makeCallAlert: boolean,
  sendbirdToken: string,
  calleeUser: {},
  isVideoCall: boolean,
  insuranceList: [],
  icdCodesList: [],
  caregiverList: [],
  authUserAllData: {},
  authPermissions: string[],
  isChartOpen: boolean,
  chartPatientDetails: any,
  todayTotalTime: string,
  isPatientChartAlreadyOpen: boolean,
  isTimerChartOpen: boolean
}

// initial state
const initialState: initialStateInt = {
  isLoading: true,
  timezoneArr: [],
  showSidebar: 'show-menu',
  windowWidth: window.innerWidth,
  isMobile: window.innerWidth <= 768,
  twilioAuthToken: '',
  notificationData: [],
  wrapperType: 'wrapper_fix',
  ccmConsentData: [],
  ccmEligibleData: [],
  recordableMinutesData: [],
  recordingStatusList: [],
  userTypes: [],
  providerTypes: [],
  faxTemplateType: [],
  faxDestinationType: [],
  stateList: [],
  providerList: [],
  relationshipList: [],
  reportAdvocateList: [],
  agencyTypes: [],
  overviewType: '',
  filtersObj: { filters: [], sort: [], hideColumns: [] },
  overviewMeasurementValues: [],
  menus: [],
  patientEvents: [],
  organizationList: [],
  campaignList: [],
  makeCallAlert: false,
  sendbirdToken: '',
  calleeUser: {},
  isVideoCall: false,
  insuranceList: [],
  icdCodesList: [],
  caregiverList: [],
  authUserAllData: {},
  authPermissions: [],
  isChartOpen: false,
  chartPatientDetails: {},
  todayTotalTime: '00:00:00',
  isPatientChartAlreadyOpen: false,
  isTimerChartOpen: false
};

// methods
// export const getTasksCount = createAsyncThunk(
//   'tasks/tasksCount',
//   async (credentials: any) => {
//     const taskDetails = await axiosGet(
//       apiRouter.TASK_BASE_URL, null
//     )
//     if (taskDetails.status) {
//       return taskDetails.data.total
//     }
//   }
// );
// states
export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setTimezoneArr: (state, { payload }) => {
      state.timezoneArr = payload
    },
    showSidebarChange: (state, { payload }) => {
      state.showSidebar = payload
    },
    setWindowWidth: (state, { payload }) => {
      state.windowWidth = payload
    },
    checkIsMobile: (state, { payload }) => {
      state.isMobile = state.windowWidth <= 768
    },
    setTwilioAuthToken: (state, { payload }) => {
      state.twilioAuthToken = payload
    },
    setNotificationData: (state, { payload }) => {
      state.notificationData = payload
    },
    setWrapperChange: (state, { payload }) => {
      state.wrapperType = payload
    },
    setCcmConsentData: (state, { payload }) => {
      state.ccmConsentData = payload
    },
    setCcmEligibleData: (state, { payload }) => {
      state.ccmEligibleData = payload
    },
    setRecordableMinutes: (state, { payload }) => {
      state.recordableMinutesData = payload
    },
    setRecordingStatus: (state, { payload }) => {
      state.recordingStatusList = payload
    },
    setUserTypes: (state, { payload }) => {
      state.userTypes = payload
    },
    setProviderTypes: (state, { payload }) => {
      state.providerTypes = payload
    },
    setFaxTemplateType: (state, { payload }) => {
      state.faxTemplateType = payload
    },
    setFaxDestinationType: (state, { payload }) => {
      state.faxDestinationType = payload
    },
    setStateList: (state, { payload }) => {
      state.stateList = payload
    },
    setProviderList: (state, { payload }) => {
      state.providerList = payload // for provider which at provider grid
    },
    setRelationships: (state, { payload }) => {
      state.relationshipList = payload
    },
    setReportAdvocateList: (state, { payload }) => {
      state.reportAdvocateList = payload
    },
    setAgencyTypes: (state, { payload }) => {
      state.agencyTypes = payload
    },
    setOverviewType: (state, { payload }) => {
      state.overviewType = payload
    },
    setFilters: (state, { payload }) => {
      state.filtersObj = payload
    },
    setOverviewMeasurementValues: (state, { payload }) => {
      state.overviewMeasurementValues = payload
    },
    setMenus: (state, { payload }) => {
      state.menus = payload
    },
    setPatientEvents: (state, { payload }) => {
      state.patientEvents = payload
    },
    setOrganizationList: (state, { payload }) => {
      state.organizationList = payload
    },
    setCampaignList: (state, { payload }) => {
      state.campaignList = payload
    },
    setMakeCallAlert: (state, { payload }) => {
      state.makeCallAlert = payload
    },
    setCalleeUser: (state, { payload }) => {
      state.calleeUser = payload
    },
    setSendbirdToken: (state, { payload }) => {
      state.sendbirdToken = payload
    },
    setIsVideoCall: (state, { payload }) => {
      state.isVideoCall = payload
    },
    setInsuranceList: (state, { payload }) => {
      state.insuranceList = payload
    },
    setIcdCodesList: (state, { payload }) => {
      state.icdCodesList = payload
    },
    setCaregiverList: (state, { payload }) => {
      state.caregiverList = payload
    },
    setAuthUserAllData: (state, { payload }) => {
      state.authUserAllData = payload
    },
    setAuthPermissions: (state, { payload }) => {
      state.authPermissions = payload
    },
    setIsChartOpen: (state, { payload }) => {
      state.isChartOpen = payload
    },
    setChartPatientDetails: (state, { payload }) => {
      state.chartPatientDetails = payload
    },
    seUserTodayTotalTime: (state, { payload }) => {
      state.todayTotalTime = payload
    },
    setIsPatientChartAlreadyOpen: (state, { payload }) => {
      state.isPatientChartAlreadyOpen = payload
    },
    setIsTimerChartOpen: (state, { payload }) => {
      state.isTimerChartOpen = payload
    }
}
  //   extraReducers: (builder) => {
  //     builder.addCase(getTasksCount.pending, (state) => {
  //       state.taskCount = 'loading...'
  //     })
  //     builder.addCase(getTasksCount.fulfilled, (state, { payload }) => {
  //       state.taskCount = payload
  //     })
  //     builder.addCase(getTasksCount.rejected, (state) => {
  //       state.taskCount = ''
  //     })
  //   }
})

// export const allUsers = (state) =>

export const { setIsLoading, setTimezoneArr, showSidebarChange, setWindowWidth, checkIsMobile, setTwilioAuthToken, setNotificationData, setWrapperChange, setCcmConsentData, setCcmEligibleData, setRecordableMinutes, setRecordingStatus, setUserTypes, setProviderTypes, setFaxTemplateType, setFaxDestinationType, setStateList, setProviderList, setRelationships, setReportAdvocateList, setAgencyTypes, setOverviewType, setFilters, setOverviewMeasurementValues, setMenus, setPatientEvents, setOrganizationList, setCampaignList, setMakeCallAlert, setSendbirdToken, setCalleeUser, setIsVideoCall, setInsuranceList, setIcdCodesList, setCaregiverList, setAuthUserAllData, setAuthPermissions, setIsChartOpen, setChartPatientDetails, seUserTodayTotalTime, setIsPatientChartAlreadyOpen, setIsTimerChartOpen } = globalSlice.actions

export default globalSlice.reducer
