import React, { Component } from 'react';
// import './scss/style.scss';
import './App.css';
import './scss/styles.scss';
import './index.css';
import 'react-photo-view/dist/react-photo-view.css';
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router
} from 'react-router-dom';
import { connect } from 'react-redux';
import LogRocket from 'logrocket';
import axios from 'axios';
// import { jwtDecode } from 'jwt-decode';

import { setIsLoading, setTimezoneArr, setWindowWidth, checkIsMobile, setNotificationData, setRecordableMinutes, setRecordingStatus, setUserTypes, setProviderTypes, setFaxTemplateType, setFaxDestinationType, setStateList, setProviderList, setRelationships, setReportAdvocateList, setAgencyTypes, setOverviewType, setOverviewMeasurementValues, setMenus, setPatientEvents, setOrganizationList, setCampaignList, setInsuranceList, setIcdCodesList, setCaregiverList, setAuthUserAllData, setAuthPermissions, seUserTodayTotalTime } from './redux/globalSlice';
import { setDashboard } from './redux/dashboardSlice';
import { setAuthToken, setIsLogin, setLoginUser } from './redux/loginSlice';
import { getUserToken } from './utils/globalFunctions'
import Forgotpassword from './views/pages/forgotpassword/Forgotpassword';
import { setPatientChannelList } from './redux/pubnubSlice';
import { toasterAlert } from './redux/toasterSlice';

let source = axios.CancelToken.source();
// Change this key with your LogRocket account.
const LOG_ROCKET_PROJECT_ID = 'gafz4p/rpm'

const loading = (
  <>
    <div className="flex justify-center items-center h-screen">
      <div className="" data-testid="test-loader">
          <div className="flex items-center justify-center ">
            <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
          </div>
      </div>
    </div>
  </>
);
const Login = React.lazy(() => import('./views/pages/login/Login'));
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

interface InputProps {
  isLogin: boolean;
  isLoading: boolean;
  setAuthToken: (token: string) => void;
  setIsLogin: (flag: boolean) => void;
  setIsLoading: (flag: boolean) => void;
  setLoginUser: (user: any) => void;
  setTimezoneArr: (timezone: any[]) => void;
  setDashboard: (dashboard: any) => void;
  setWindowWidth: (data: any) => void;
  checkIsMobile: (flag: boolean) => void;
  setNotificationData: (notificationStatuses: any[]) => void;
  windowWidth: any;
  isMobile: boolean;
  setRecordableMinutes: (data: any[]) => void;
  setRecordingStatus: (data: any[]) => void;
  setUserTypes: (data: any[]) => void;
  setProviderTypes: (data: any[]) => void;
  setPatientChannelList: (data: any[]) => void;
  setFaxTemplateType: (data: any[]) => void;
  setFaxDestinationType: (data: any[]) => void;
  setStateList: (data: any[]) => void;
  setProviderList: (data: any[]) => void;
  setRelationships: (data: any[]) => void;
  setReportAdvocateList: (data: any[]) => void;
  setAgencyTypes: (data: any[]) => void;
  setOverviewType: (data: string) => void;
  setOverviewMeasurementValues: (data: string) => void;
  setMenus: (data: any[]) => void;
  setPatientEvents: (data: any[]) => void;
  toasterAlert: (data: any) => void;
  setOrganizationList: (data: any[]) => void;
  setCampaignList: (data: any[]) => void;
  setInsuranceList: (data: any[]) => void;
  setIcdCodesList: (data: any[]) => void;
  setCaregiverList: (data: any[]) => void;
  setAuthUserAllData:(data: {}) => void;
  setAuthPermissions: (data: any[]) => void;
  seUserTodayTotalTime: (data: {}) => void;
}
class App extends Component<InputProps, any> {
  constructor (props: InputProps) {
    super(props);
    source = axios.CancelToken.source();
    this.state = {
      isPageLoader: true
    };
  }

  handleWindowSizeChange = () => {
    const { setWindowWidth, checkIsMobile, isMobile, isLogin, windowWidth } = this.props
    setWindowWidth(window.innerWidth);
    checkIsMobile(windowWidth <= 768);
    if (isMobile && isLogin) {
      if (!['/patients'].includes(window.location.pathname)) {
        window.location.pathname = '/patients';
      }
    }
  }

  async componentDidMount () {
    this.setState({ isPageLoader: true });
    const { setAuthToken, setIsLogin } = this.props;
    window.addEventListener('resize', this.handleWindowSizeChange);
    const authToken = localStorage.getItem('RPMAuthToken');
    if (authToken !== null && authToken !== '' && authToken !== ' ') {
     setAuthToken(authToken);
     await this.allAxiosRequest();
     setIsLogin(true);
      // this.getLoggedInUser();
      // return loading;
    }
    this.props.setIsLoading(false);
    this.handleWindowSizeChange();
    if (authToken === null || authToken === '') {
      this.setState({ isPageLoader: false });
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleWindowSizeChange);
    if (source) {
      source.cancel('Request canceled')
    }
  }

	componentDidUpdate (prevProps: any, prevState: any, snapshot: any) {
    LogRocket.init(LOG_ROCKET_PROJECT_ID)
  }

  allAxiosRequest = async () => {
    const cancelToken = source.token;
    const localToken = getUserToken();
    const dashBoardRequest = axios.get(`${process.env.REACT_APP_API_URL}dashboard`, {
      headers: { Authorization: `${localToken || null}` }
    });
    const providerListRequest = axios.get(`${process.env.REACT_APP_API_URL}api/dashboard/dropdown?type=providers`, {
      headers: { Authorization: `${localToken || null}` }
    });
    const initialRequest = axios.get(`${process.env.REACT_APP_API_URL}api/initial`, {
      headers: { Authorization: `${localToken || null}` }
    });
    const TotalTimeRequest = axios.get(`${process.env.REACT_APP_API_URL}api/total_time`, {
      headers: { Authorization: `${localToken || null}` }
    });

    const { setLoginUser, setTimezoneArr, setDashboard, setNotificationData, setRecordableMinutes, setRecordingStatus, setUserTypes, setProviderTypes, setFaxTemplateType, setFaxDestinationType, setStateList, setProviderList, setRelationships, setReportAdvocateList, setAgencyTypes, setOverviewType, setOverviewMeasurementValues, setMenus, setPatientEvents, setOrganizationList, setCampaignList, setInsuranceList, setIcdCodesList, setCaregiverList, setAuthUserAllData, setAuthPermissions, seUserTodayTotalTime } = this.props;
    // setTimeout(async () => {
      await axios.all([dashBoardRequest, providerListRequest, initialRequest, TotalTimeRequest]).then(axios.spread((responses: any, providersData: any[], initialResponse: any[], totalTimeResponse: any) => {
        let data:any = [];
        data = responses;
        setLoginUser(data.user);
        setTimezoneArr(data.timezones);
        setDashboard(data);
        setNotificationData(data.notificationStatuses)
        this.props.setIsLogin(true);
        this.setState({ isPageLoader: false });
        setRecordableMinutes(data?.recordableMinutes)
        setRecordingStatus(data?.recordingStatus)
        setUserTypes(data?.userTypes)
        setProviderTypes(data?.providerTypes)
        setFaxTemplateType(data?.faxTemplates)
        setFaxDestinationType(data?.faxDestinations)
        setStateList(data?.states)
        setRelationships(data?.relationships)
        setReportAdvocateList(data?.advocates)
        setAgencyTypes(data?.patientAgencyType)
        setOverviewMeasurementValues(data?.measurementValues)
        setMenus(data?.menus)
        setPatientEvents(data?.patientEvents)
        setProviderList(providersData)
        setOverviewType(data?.overviewType)
        setOrganizationList(data?.organizations)
        setCampaignList(data?.campaign)
        setInsuranceList(data?.insurance)
        setIcdCodesList(data?.icdCodes)
        setCaregiverList(data?.caregivers)
        const initialResponseData: any = initialResponse;
        setAuthUserAllData(initialResponseData)
        setAuthPermissions(initialResponseData?.permissions)
        if (totalTimeResponse?.totalTime !== '') {
          seUserTodayTotalTime(totalTimeResponse?.totalTime)
        }
      })).catch(errors => {
        // react on errors.
        // this.props.toasterAlert({
        //   type: 'error',
        //   title: 'Something went wrong!',
        //   message: errors?.response?.data?.hint ?? 'There is some issue, please try after sometime.'
        // });
      })
      // }, 1000);
  }

  render () {
    const { isLoading, isLogin } = this.props;
    const { isPageLoader } = this.state;
    return (
      <>
      {!isPageLoader
        ? <Router>
           <React.Suspense fallback={loading}>
              {!isLoading
                ? <Switch>
                    <Route
                      exact
                      path="/login"
                      render={ props => <Login {...props} loginComplete={this.allAxiosRequest} />}
                    />
                    <Route
                      exact
                      path="/forgotpassword"
                      render={ props => <Forgotpassword {...props} />}
                    />
                    <Route
                      exact
                      path="/resetpassword"
                      render={ props => <Forgotpassword {...props} />}
                    />
                    { isLogin && !isPageLoader
                      ? <Route
                      path="/"
                      render={ props => <TheLayout {...props}/> }
                    />
                      : <Redirect to="/login" />
                    }
                  </Switch>
                : loading
              }
            </React.Suspense>
        </Router>
        : loading
          }
      </>
    );
  }
}

axios.interceptors.request.use(function (config: any) {
  config.headers = { ...config.headers, 'Content-Type': 'application/json' }

  if (localStorage.getItem('RPMAuthToken')) {
    config.headers = { ...config.headers, Authorization: localStorage.getItem('RPMAuthToken') }
  }
  return config;
}, function () {
})

axios.interceptors.response.use(
  response => {
    return response.status === 200 ? response.data : response
  },
  error => {
    if (error.response && error.response.status && error.response.status === 401) {
      localStorage.removeItem('RPMAuthToken');
      window.location.reload();
    }
    return Promise.reject(error);
  }
)

function mapStateToProps (state: any) {
  return {
    isLogin: state.user_loggedin.isLogin,
    isLoading: state.global.isLoading,
    isMobile: state.global.isMobile,
    windowWidth: state.global.windowWidth
  };
}

const mapDispatchToProps = {
  setAuthToken,
  setIsLogin,
  setIsLoading,
  setLoginUser,
  setDashboard,
  setTimezoneArr,
  setWindowWidth,
  checkIsMobile,
  setNotificationData,
  setRecordableMinutes,
  setRecordingStatus,
  setUserTypes,
  setPatientChannelList,
  setProviderTypes,
  setFaxTemplateType,
  setFaxDestinationType,
  setStateList,
  setProviderList,
  setRelationships,
  setReportAdvocateList,
  setAgencyTypes,
  setOverviewType,
  setOverviewMeasurementValues,
  setMenus,
  setPatientEvents,
  toasterAlert,
  setOrganizationList,
  setCampaignList,
  setInsuranceList,
  setIcdCodesList,
  setCaregiverList,
  setAuthUserAllData,
  setAuthPermissions,
  seUserTodayTotalTime
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
