import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { resetPassword, forgotpassword } from '../../../services/AuthService';
import { connect } from 'react-redux';
import { preventEnterSubmit } from '../../../utils/Constant';
import { setIsLogin } from '../../../redux/loginSlice';
import { toasterAlert } from '../../../redux/toasterSlice';
import SimpleInput from '../../../components/SimpleInput';
import InputBtn from '../../../components/InputBtn';
import { BsLock, BsPerson } from 'react-icons/bs';

const validationSchema = function (values: any) {
  return Yup.object().shape({
      username: Yup.string().email('Must be a valid email').required('User name is required')
  });
};
const validationSchemaResetPassword = function (values: any) {
  return Yup.object().shape({
    password: Yup.string()
      .min(8, `Password has to be at least ${8} characters!`)
      .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/, 'Password must contain: numbers, uppercase and lowercase letters\n')
      .required('New password is require'),
    confirmationCode: Yup.string().required('Confirmation code is required'),
    username: Yup.string().email('Must be a valid email').required('User name is required')
  });
};

const getErrorsFromValidationError = (validationError: any) => {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR]
    };
  }, {});
};

const validate = (getValidationSchema: any) => {
  return (values: any) => {
    const validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

interface InputProps {
  toasterAlert: (data: any) => void;
  isLogin?: boolean;
  history: any;
  isMobile: boolean;
  location: any;
}
class Forgotpassword extends React.Component<InputProps, any> {
  constructor (props: InputProps) {
    super(props);
    this.state = {
      showResetPassword: false
    };
  }

  componentDidMount () {
    if (this.props?.location?.pathname === '/resetpassword') {
      this.setState({
        showResetPassword: true
      })
    }
  }

  redirectToLogin = () => {
    this.props.history.push('/login');
  }

  forgotPasswordCall = (values: any, actions: any) => {
    const username = values.username;
    forgotpassword(username).then(async (data: any) => {
        if (data.CodeDeliveryDetails !== undefined && data.CodeDeliveryDetails !== '') {
          actions.setSubmitting(false);
          actions.resetForm();
          this.setState({
            showResetPassword: true
          })
          this.props.toasterAlert({
            type: 'success',
            title: 'Success',
            message: 'You have got confirmation code in email.'
          });
        } else {
          actions.setSubmitting(false);
          this.props.toasterAlert({
            type: 'error',
            title: 'Something went wrong!',
            message: 'There is some issue, please try after sometime.'
          });
        }
      })
      .catch((e) => {
        actions.setSubmitting(false);
        this.props.toasterAlert({
          type: 'error',
          title: 'Something went wrong!',
          message: e?.response?.data?.hint ?? 'There is some issue, please try after sometime.'
        });
      });
  };

  onSubmitChangePassword = (values: any, actions: any) => {
    const dataObj: any = {
      confirmationCode: values.confirmationCode,
      username: values.username,
      password: values.password
    };
    const authData = JSON.stringify(dataObj);

    resetPassword(authData).then((data: any) => {
      this.props.toasterAlert({
        title: 'Reset Success!',
        message: 'Your password reset successfully!!'
      });
      setTimeout(() => {
        this.props.history.push('/login');
      }, 2500)
    })
    .catch((e) => {
      actions.setSubmitting(false);
      this.props.toasterAlert({
        type: 'error',
        title: 'Something went wrong!',
        message: e?.response?.data?.hint ?? 'There is some issue, please try after sometime.'
      });
    });
  };

  render () {
    return (
      <div className="c-app flex justify-center items-center w-full h-full xl:w-1/2 mx-auto">
          <div className="irpm__row flex-col justify-center">
            <div className="login-logo-container">
              <img className="login-logo-image" src="images/InsightLogin.png" alt="" />
            </div>
            <div className="w-full login-box">
              <div className='irpm__card flex p-0 h-full'>
                <div className="irpm__card-body p-4 flex-1">
                    {
                      this.state.showResetPassword
                        ? <Formik
                            initialValues={{ username: '', confirmationCode: '', password: '' }}
                            validate={validate(validationSchemaResetPassword)}
                            onSubmit={this.onSubmitChangePassword}
                          >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            handleReset
                          }) => (
                            <form onKeyDown={preventEnterSubmit} onSubmit={handleSubmit} noValidate name="changePasswordForm">
                              <h1 className="text-4xl font-semibold mb-2">Reset Password</h1>
                              <p className="text-muted mb-3">Reset your password</p>
                              <div className="flex mb-4">
                                <span className="inline-flex items-center px-3 py-2 text-sm text-gray-900 bg-blue-100 rounded-l-md border border-r-0 border-gray-300">
                                  <BsPerson style={{ fontSize: '1.1rem' }}/>
                                </span>
                                <SimpleInput
                                  type="text"
                                  name="username"
                                  id="username"
                                  placeholder="User Name"
                                  autoComplete="username"
                                  valid={!errors.username}
                                  invalid={touched.username && !!errors.username}
                                  required
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.username}
                                />
                              </div>
                              <div className="flex mb-4">
                                <span className="inline-flex items-center px-3 py-2 text-sm text-gray-900 bg-blue-100 rounded-l-md border border-r-0 border-gray-300">
                                  <BsLock style={{ fontSize: '1.1rem' }}/>
                                </span>
                                <SimpleInput
                                  type="text"
                                  placeholder="Confirm Code"
                                  autoComplete="new-password"
                                  name="confirmationCode"
                                  id="confirmationCode"
                                  valid={!errors.confirmationCode}
                                  invalid={touched.confirmationCode && !!errors.confirmationCode}
                                  required
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.confirmationCode}
                                />
                              </div>
                              <div className="flex mb-4">
                                <span className="inline-flex items-center px-3 py-2 text-sm text-gray-900 bg-blue-100 rounded-l-md border border-r-0 border-gray-300">
                                  <BsLock style={{ fontSize: '1.1rem' }}/>
                                </span>
                                <SimpleInput
                                  type="password"
                                  placeholder="New Password"
                                  autoComplete="new-password"
                                  name="password"
                                  id="password"
                                  valid={!errors.password}
                                  invalid={touched.password && !!errors.password}
                                  required
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                />
                              </div>
                              <div className="irpm__row">
                                <div className="col-6">
                                  <InputBtn
                                    type="submit"
                                    className="px-4 button button-primary"
                                    disabled={isSubmitting || !isValid}
                                  >
                                    {isSubmitting ? 'Wait...' : 'Reset Password'}
                                  </InputBtn>
                                </div>
                                <div className="col-6 text-right">
                                  <button color="link" className="px-0 nounderline btn-link" onClick={this.redirectToLogin}>
                                    Login ?
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                          </Formik>

                        : <Formik
                            initialValues={{ username: '' }}
                            validate={validate(validationSchema)}
                            onSubmit={this.forgotPasswordCall}
                          >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            handleReset
                          }) => (
                            <form onSubmit={handleSubmit} noValidate name="forgotForm">
                              <h1 className="text-2xl font-semibold mb-2">Forgot Password</h1>
                              <div className="flex mb-4">
                                <span className="inline-flex items-center px-3 py-2 text-sm text-gray-900 bg-blue-100 rounded-l-md border border-r-0 border-gray-300">
                                  <BsPerson style={{ fontSize: '1.1rem' }}/>
                                </span>
                                <SimpleInput
                                  type="text"
                                  name="username"
                                  id="username"
                                  placeholder="Username"
                                  autoComplete="username"
                                  valid={!errors.username}
                                  invalid={touched.username && !!errors.username}
                                  required
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.username}
                                />
                              </div>

                              <div className="irpm__row">
                                <div className="col-6">
                                  <InputBtn
                                    type="submit"
                                    className="px-8 button button-primary"
                                    disabled={isSubmitting || !isValid}
                                  >
                                    {isSubmitting ? 'Wait...' : 'Submit'}
                                  </InputBtn>
                                </div>
                                <div className="col-6 text-right">
                                  <button color="link" className="px-0 nounderline btn-link" onClick={this.redirectToLogin}>
                                    Login?
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                      </Formik>
                    }
                </div>
                <div className="login-fold flex-1">
                  <div className="login-image">
                    <img src="images/loginBanner.jpg" alt=""/>
                  </div>
                  <h4>
                    Reducing hospitalizations through preventative care.
                  </h4>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}
function mapStateToProps (state: any) {
  return {
    isLogin: state.user_loggedin.isLogin,
    isMobile: state.global.isMobile
  };
}

const mapDispatchToProps = {
  toasterAlert,
  setIsLogin
};

export default connect(mapStateToProps, mapDispatchToProps)(Forgotpassword);
